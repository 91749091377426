<!--
 * ======================================
 * 说明： 升学管理
 * 作者： Silence
 * 文件： updataterm.vue
 * 日期： 2023/8/3 0:11
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :hasAction="false" :autoLoading="true"  :options="handleOptions" :loadData="handleLoadData" title="升学管理" ref="table">
        <template #action>
          <el-button type="primary" icon="el-icon-edit" @click="handleUpgrade">一键升级</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      subject_name: '',
      statusOptions:[{
        label:'通过',
        value:1
      },{
        label:'驳回',
        value:2
      },{
        label:'处理中',
        value:3
      }],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        old_grade:'',
        new_grade:'',
      },
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/classes/table").then(res => {
        let {header, search} = res;
        console.log('header::', header)
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.page("classes/list", {size, page}, search).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 一键升级班级
    handleUpgrade(){
      this.$confirm('是否一键升级所有班级？请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("up/do",{}).then(() => {
          this.$message({
            type: 'success',
            message: '升级成功!'
          })
        })
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>
